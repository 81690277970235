import GoIcon from "../assets/icons/go.svg";
import ReactIcon from "../assets/icons/react.svg";
import JSIcon from "../assets/icons/js.svg";
import TSIcon from "../assets/icons/typescript.svg";
import AWSIcon from "../assets/icons/aws.svg";
import K8sIcon from "../assets/icons/k8s.svg";
import { Element } from "react-scroll";
import { motion } from "framer-motion";
import { Reveal } from "../components/Reveal";

const aboutImageMain = new URL(
  "../assets/images/About-Pic-1.webp",
  import.meta.url
);
const aboutImageSecondary = new URL(
  "../assets/images/About-Pic-2.webp",
  import.meta.url
);

export function About() {
  return (
    <Element
      name="about"
      className="grid grid-cols-12 gap-8 mt-48 md:px-24 md:mt-64 2xl:px-56 snap-center"
    >
      <div className="flex flex-col items-center justify-center col-span-12 text-center row-span-8 md:items-start md:text-start md:col-span-7">
        <Reveal delay={0.1}>
          <>
            <img
              className="absolute z-0 w-56 h-48 pb-8 -translate-x-12 -translate-y-24 rounded-sm md:hidden transform-gpu -rotate-12"
              alt="Looking at Rajgad Fort"
              src={aboutImageSecondary.toString()}
            />

            <img
              className="z-10 w-56 h-48 pb-8 translate-x-10 rounded-sm md:hidden transform-gpu"
              alt="Paragliding above Mahabaleshwar, Maharashtra"
              src={aboutImageMain.toString()}
            />
          </>
        </Reveal>
        <Reveal>
          <h1 className="py-4 font-serif text-4xl md:text-5xl">
            A little bit about me
          </h1>
        </Reveal>
        <Reveal delay={0.1}>
          <h3 className="px-4 text-justify text-md md:px-0 md:text-lg">
            I caught the computer bug early on in life, as many do, initially
            through many hours of playing video games. Gradually, my curiosity
            led me down the path of wanting to create them myself. So, I started
            learning C# and Unity and created some basic projects. However, I
            soon discovered that making games is not just about coding. It
            requires skills in various fields like art, animation, sound design,
            etc. <br />
            Around that time, my parents gifted me a Windows Phone and I
            couldn't help but notice a lack of good apps on the platform - even
            the calculator on the platform was basic. So, I decided to make an
            advanced calculator. It was a comfortable transition to become an
            app developer as I had already learnt C# and just needed to learn
            XAML and UI design. I never expected anyone to use it, however
            unexpectedly, it started gaining traction, so much so, that even{" "}
            <a
              href="https://www.windowscentral.com/10calc-windows-phone-app-review"
              className="dark:text-primaryLight text-primary"
              target="_blank"
            >
              Windows Central
            </a>{" "}
            covered it. That was my first taste of making something that people
            use and love. I was hooked. I started contributing to open source
            projects, building some of my own and have been doing so ever since.
            <br /> <br />
            In my free time, I like to read books, play Table Tennis, code
            (nerd!), play video games, and travel (sometimes).
            <br /> <br />
            Below are the technologies I am currently using:
          </h3>
        </Reveal>

        <div className="flex gap-4 pt-3">
          <Reveal delay={0.1}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a
                href="https://developer.mozilla.org/en-US/docs/Glossary/JavaScript"
                target="_blank"
                aria-label="JavaScript Homepage"
              >
                <JSIcon className="w-8 h-8 cursor-pointer" />
              </a>
            </motion.div>
          </Reveal>
          <Reveal delay={0.2}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a
                href="https://www.typescriptlang.org/"
                target="_blank"
                aria-label="TypeScript Homepage"
              >
                <TSIcon className="w-8 h-8 cursor-pointer" />
              </a>
            </motion.div>
          </Reveal>
          <Reveal delay={0.3}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a
                href="https://react.dev/"
                target="_blank"
                aria-label="React Homepage"
              >
                <ReactIcon className="w-8 h-8 cursor-pointer" />
              </a>
            </motion.div>
          </Reveal>
          <Reveal delay={0.4}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a
                href="https://go.dev/"
                target="_blank"
                aria-label="Go Homepage"
              >
                <GoIcon className="w-8 h-8 cursor-pointer" />
              </a>
            </motion.div>
          </Reveal>
          <Reveal delay={0.5}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a
                href="https://aws.amazon.com/"
                target="_blank"
                aria-label="AWS Homepage"
              >
                <AWSIcon className="w-8 h-8 cursor-pointer fill-current" />
              </a>
            </motion.div>
          </Reveal>
          <Reveal delay={0.5}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a
                href="https://kubernetes.io/"
                target="_blank"
                aria-label="Kubernetes Homepage"
              >
                <K8sIcon className="w-8 h-8 cursor-pointer" />
              </a>
            </motion.div>
          </Reveal>
        </div>
      </div>

      <div className="items-center justify-center hidden col-span-5 col-start-8 md:flex row-span-8">
        <Reveal delay={0.1}>
          <>
            <img
              className="absolute z-0 h-48 -translate-x-16 -translate-y-32 rounded-sm w-100 transform-gpu -rotate-12"
              alt="Looking at Rajgad Fort"
              src={aboutImageSecondary.toString()}
            />

            <img
              className="z-10 h-48 rounded-sm translate-x-14 transform-gpu"
              alt="Paragliding above Mahabaleshwar, Maharashtra"
              src={aboutImageMain.toString()}
            />
          </>
        </Reveal>
      </div>
    </Element>
  );
}
