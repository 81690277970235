import GithubIcon from "../assets/icons/github.svg";
import LinkedInIcon from "../assets/icons/linkedin.svg";
import TelegramIcon from "../assets/icons/telegram.svg";
import GmailIcon from "../assets/icons/gmail.svg";
import { Element, Link } from "react-scroll";
import { motion } from "framer-motion";
import { Reveal } from "../components/Reveal";

const profileImage = new URL("../assets/Profile.webp", import.meta.url);

export function Home() {
  return (
    <Element
      name="home"
      className="grid grid-cols-12 gap-8 md:px-24 snap-center 2xl:px-56"
    >
      <div className="flex flex-col items-center justify-center col-span-12 row-start-2 text-center md:items-start md:row-start-8 md:col-span-7 md:text-start">
        <motion.img
          className="mb-8 border-2 border-gray-100 rounded-full w-36 h-36 md:hidden"
          alt="Prajjwal Dimri"
          src={profileImage.toString()}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
        />
        <Reveal>
          <h3 className="uppercase text-md text-primary md:text-lg">
            Hi, My Name is Prajjwal
          </h3>
        </Reveal>
        <Reveal delay={0.1}>
          <h1 className="px-4 py-4 font-serif text-4xl md:px-0 md:text-5xl">
            I craft experiences on the Web
          </h1>
        </Reveal>
        <Reveal delay={0.2}>
          <h3 className="px-4 text-md md:text-lg md:px-0">
            I’m a full-stack developer, currently working @{" "}
            <a
              href="https://warpbuild.com"
              className="dark:text-primaryLight text-primary"
              aria-label="Link to WarpBuild's website"
              target="_blank"
            >
              WarpBuild
            </a>
            . I like working with cutting-edge tech and building features
            end-to-end.
          </h3>
        </Reveal>

        <div className="flex gap-4 pt-6">
          <Reveal delay={0.3}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a
                href="mailto:prajjwaldimri@outlook.com"
                aria-label="Mail to Prajjwal"
              >
                <GmailIcon className="w-6 h-6 cursor-pointer" />
              </a>
            </motion.div>
          </Reveal>

          <Reveal delay={0.4}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a
                href="https://www.linkedin.com/in/prajjwaldimri/"
                target="_blank"
                aria-label="Prajjwal's LinkedIn Profile"
              >
                <LinkedInIcon className="w-6 h-6 cursor-pointer" />
              </a>
            </motion.div>
          </Reveal>

          <Reveal delay={0.5}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a
                href="https://github.com/prajjwaldimri"
                target="_blank"
                aria-label="Prajjwal's Github Profile"
              >
                <GithubIcon className="w-6 h-6 cursor-pointer" />
              </a>
            </motion.div>
          </Reveal>

          <Reveal delay={0.6}>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <a
                href="https://t.me/prajjwaldimri"
                target="_blank"
                aria-label="Prajjwal's Telegram Profile"
              >
                <TelegramIcon className="w-6 h-6 cursor-pointer" />
              </a>
            </motion.div>
          </Reveal>
        </div>

        <motion.div
          whileHover={{ y: -2 }}
          className="mt-6 text-xs text-gray-400 cursor-pointer md:mt-56 dark:text-gray-600 md:hidden"
        >
          <Link to="about" smooth={true} offset={-130}>
            scroll to see more
          </Link>
        </motion.div>
      </div>
      <div className="justify-center hidden col-span-3 col-start-9 row-start-8 md:flex">
        <Reveal delay={0.2}>
          <motion.img
            className="w-48 h-48 mb-8 border-2 border-gray-100 rounded-full"
            alt="Prajjwal Dimri"
            src={profileImage.toString()}
          />
        </Reveal>
      </div>
    </Element>
  );
}
