import { PiArrowSquareOut } from "react-icons/pi";
import { Element } from "react-scroll";
import { Reveal } from "../components/Reveal";
import { motion } from "framer-motion";

export function Writing() {
  const blogList = [
    {
      title:
        "Cost comparison: GitHub Actions Runner Controller (ARC) and WarpBuild",
      href: "https://www.warpbuild.com/blog/arc-warpbuild-comparison-case-study",
    },
    {
      title: "Supercharge your CI with Snapshot Runners",
      href: "https://www.warpbuild.com/blog/snapshot-runners",
    },
    {
      title: "Rate Limit Cheatsheet for Self-Hosting Github Runners",
      href: "https://www.warpbuild.com/blog/rate-limits-self-hosted-runners",
    },
    {
      title: "Optimizing Dockerfiles for Fast Builds",
      href: "https://www.warpbuild.com/blog/optimizing-docker-builds",
    },
    {
      title: "Technical Exploration of Argonaut's CI Pipelines",
      href: "https://web.archive.org/web/20231214043957/https://www.argonaut.dev/blog/ci-pipelines-technical-blog",
    },
    {
      title:
        "Engineering: A Technical Exploration of Argonaut's Notifications System",
      href: "https://web.archive.org/web/20231214082455/https://www.argonaut.dev/blog/notifications-architecture",
    },
    {
      title: "Why is my jest runner not exiting?",
      href: "https://prajjwaldimri.medium.com/why-is-my-jest-runner-not-closing-bc4f6632c959",
    },
    {
      title: "Rails and Semantic: The form_for and name: problem",
      href: "https://prajjwaldimri.medium.com/rails-and-semantic-the-form-for-and-name-problem-8d357d9c5fe6",
    },
    {
      title:
        "Mvvm-Cross and Xamarin Navigation: Value cannot be null. Parameter /key",
      href: "https://prajjwaldimri.medium.com/mvvm-cross-and-xamarin-navigation-value-cannot-be-null-parameter-key-f4da6961b845",
    },
  ];

  return (
    <Element
      name="blogs"
      className="grid grid-cols-12 gap-8 mt-20 mb-20 md:px-24 2xl:px-56 md:mt-56 md:mb-56 snap-center"
    >
      <div className="flex flex-col items-center justify-center col-span-12 text-center row-span-8 md:items-start md:text-start">
        <Reveal>
          <h1 className="pt-2 font-serif text-4xl md:text-5xl">My Writing</h1>
        </Reveal>

        <div className="flex flex-col items-center gap-2 px-4 mt-6 md:mt-8 md:px-0 md:items-start md:gap-5">
          {blogList.map((blog, index) => (
            <Reveal delay={index * 0.1} key={index}>
              <motion.div
                initial={{ scale: 1, color: "inherit" }}
                whileHover={{ scale: 1.05, color: "#525CEB" }}
                whileTap={{ scale: 0.95 }}
              >
                <a
                  href={blog.href}
                  className="flex items-center cursor-pointer text-inherit md:text-lg"
                  target="_blank"
                >
                  <span>
                    {blog.title}
                    <PiArrowSquareOut className="inline-block w-4 h-4 mb-1 ml-1 opacity-60" />
                  </span>
                </a>
              </motion.div>
            </Reveal>
          ))}
        </div>
      </div>
    </Element>
  );
}
